<template>
  <v-sheet class="pa-5" elevation="1">
    <div class="d-flex flex-row align-center justify-space-between px-10">
      <div>
        <div>
          <v-row no-gutters>
            <v-btn color="grey" text absolute top right @click="openEditDialog">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-col cols="4" v-for="item in profileTitles" :key="item.id">
              <v-list-item two-line class="ml-10">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                    item.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-4"></v-divider>
        <div class="ml-10">
          <v-subheader class="font-weight-bold black--text"
            >Driver(s)</v-subheader
          >
          <div>
            <v-chip
              class="ma-2 px-5"
              color="primary"
              v-for="item in assignedDrivers"
              :key="item"
            >
              {{ item }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ "Edit Van" }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined dense v-model="item.value"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                "Garage Address"
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-select
                outlined
                :items="garageList"
                item-text="name"
                return-object
                dense
                v-model="toEdit.garageAddress"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Technical Visit Due Date</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="technicalDueDate"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="techDate = parseDate(technicalDueDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="techDate"
                  @input="dateMenu = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Insurance Due Date</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="insuranceDueDate"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="insuranceDate = parseDate(insuranceDueDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="insuranceDate"
                  @input="dateMenu2 = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold"
                >Status</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-select
                outlined
                :items="statusItems"
                dense
                v-model="toEdit.status"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveEdit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import moment from "moment";

export default {
  props: {
    van: {
      type: Object,
      required: true,
    },
    updateVan: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    profileTitles: [],
    assignedDrivers: [],
    toEdit: {
      techDueDate: '',
      insuranceDueDate: ''
    },

    garageList: [],
    dateMenu: false,
    dateMenu2: false,
    techDate: "",
    technicalDueDate: "",
    insuranceDate: "",
    insuranceDueDate: "",
    garageAddress: null,
    statusItems: ["Active", "Disable"],
    formText: [
      {
        id: "brand",
        title: "Brand",
        value: "",
      },
      {
        id: "model",
        title: "Model",
        value: "",
      },
      {
        id: "registrationNumber",
        title: "Registration Number",
        value: "",
      },
    ],
  }),

  watch: {
    techDate(val) {
      this.technicalDueDate = this.formatDate(this.techDate);
    },

    insuranceDate(val) {
      this.insuranceDueDate = this.formatDate(this.insuranceDate);
    },
  },

  created() {
    let macMap = new Map(Object.entries(this.van.macroPlanning));
    // console.log(macMap)

    let objectedMap = Object.fromEntries(macMap);

    let driversIdArray = [];

    for (const [key, value] of Object.entries(objectedMap)) {
      driversIdArray.push(value.driver);
    }

    let filteredDriversArray = [
      ...new Set(driversIdArray.map((x) => x)),
    ].filter((v) => v);

    this.assignedDrivers = filteredDriversArray;

    this.technicalDueDate = this.van.techDueDate
    this.insuranceDueDate = this.van.insuranceDueDate

    this.profileTitles = [
      {
        id: 1,
        title: "Van ID",
        value: `${this.van.customId}`,
      },
      {
        id: 2,
        title: "Brand/Model",
        value: `${this.van.brandModel}`,
      },
      {
        id: 3,
        title: "Registration Number",
        value: `${this.van.registrationNumber}`,
      },
      {
        id: 4,
        title: "Technical Visit Due Date",
        value: `${this.getDate(this.van.techDueDate)}`,
      },
      {
        id: 5,
        title: "Insurance Due Date",
        value: `${this.getDate(this.van.insuranceDueDate)}`,
      },
      {
        id: 6,
        title: "Status",
        value: `${this.van.status}`,
      },
      {
        id: 7,
        title: "Addresse",
        value: `${this.van.garageAddress.name}`,
      },
    ];
  },

  methods: {
    openEditDialog() {
      this.getGarageList();

      this.dialog = true;
      this.toEdit = this.van;
      this.formText[0].value = this.toEdit.brand;
      this.formText[1].value = this.toEdit.model;
      this.formText[2].value = this.toEdit.registrationNumber;

      console.log(this.toEdit);
    },

    async saveEdit() {
      try {
        this.toEdit.brand = this.formText[0].value;
        this.toEdit.model = this.formText[1].value;
        this.toEdit.registrationNumber = this.formText[2].value;
        this.toEdit.techDueDate = this.technicalDueDate
        this.toEdit.insuranceDueDate = this.insuranceDueDate

        let status = await this.$store.dispatch("van/updateVan", this.toEdit);

        console.log(status);

        if (status.code === 1) {
          this.van = this.toEdit;
          this.van.brandModel = `${this.van.brand} ${this.van.model}`;
          this.updateVan(this.toEdit);
          this.updateProfileInfo(this.van);
          // this.$router.go()
          console.log(this.van);
          this.dialog = false;
          this.toEdit = {};
        } else {
          this.dialog = false;
          this.toEdit = {};
        }
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async getGarageList() {
      let addressList = await this.$store.dispatch("getGarageAddressList");
      this.garageList = addressList ? addressList : [];
    },

    updateProfileInfo(payload) {
      this.profileTitles = [
        {
          id: 1,
          title: "Van ID",
          value: `${payload.customId}`,
        },
        {
          id: 2,
          title: "Brand/Model",
          value: `${payload.brandModel}`,
        },
        {
          id: 3,
          title: "Registration Number",
          value: `${payload.registrationNumber}`,
        },
        {
          id: 4,
          title: "Technical Visit Due Date",
          value: `${this.getDate(payload.techDueDate)}`,
        },
        {
          id: 5,
          title: "Insurance Due Date",
          value: `${this.getDate(payload.insuranceDueDate)}`,
        },
        {
          id: 6,
          title: "Status",
          value: `${payload.status}`,
        },
        {
          id: 7,
          title: "Addresse",
          value: `${payload.garageAddress.name}`,
        },
      ];
    },

    getDate(date) {
      let formated = this.formatDate(date)
      return moment(formated)
        .locale("fr")
        .format("dddd D MMMM YYYY");
    },
  },
};
</script>

<style></style>
